<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      class="statistics-resource-table"
      do-route-filters
      headers-filterable
      no-meta-check
      prune-search-filters
      resource="discounts"
      route-base="/reports/statistics/discounts"
      show-filter
      sortable
      :buttons="computedButtons"
      :custom-resource="customResource()"
      :headers-config="headersConfig"
      :headers-default-hide="headersDefaultHide"
      :headers="headers"
      :locale="locale"
      :resource-query="resourceQuery"
      :search-filters="filtersList"
      :show-operations="false"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
      @search-filter-submit="
        $ampli.eventWithBaseProps('statisticsFiltersSearchButtonClick')
      "
    />
  </th-page-wrapper>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pRetry from 'p-retry'
import safeGet from 'just-safe-get'
import { mapGetters } from 'vuex'
import qs from 'qs'
import datatableHeadersConfig from '@/mixins/datatable-headers-config'
import {
  applyFiltersBeforeRouteEnter,
  formatDateRange,
  getDateTimeFormat,
  getRangeFor
} from '@/utils/date'
import { useExportsStore } from '@/store/exports'

export default {
  metaInfo() {
    return {
      title: this.$t('pages.reports.statistics.discounts.discounts')
    }
  },
  components: {},
  beforeRouteEnter: (to, _, next) => {
    // doing stuff here is very dangerous as it might lead to infinite route loops
    applyFiltersBeforeRouteEnter({ path: to.path, query: to.query, next })
  },
  beforeRouteUpdate(to, _, next) {
    this.$emit('route-filter', safeGet(qs.parse(to.query), 'filter') || {})
    next()
  },
  props: {
    resources: {
      type: Object,
      required: true
    }
  },
  setup() {
    const { headersConfig, handleHeadersConfig } = datatableHeadersConfig(
      'settings.headerFilters.reports.statistics.discounts'
    )
    return {
      headersConfig,
      handleHeadersConfig
    }
  },
  data() {
    return {
      isLegacy: true,
      loadingExport: false,
      filters: {},
      product: undefined,
      discount: undefined,
      width: 500,
      headersDefaultHide: ['quantity', 'discount_type', 'discount_group'],
      headers: [
        {
          label: this.$t('pages.reports.statistics.discounts.branch_name'),
          field: 'branch_name',
          fallback: '-',
          align: 'right',
          minWidth: 150,
          truncate: true
        },
        {
          label: this.$t('pages.reports.statistics.discounts.date'),
          field: 'date',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180,
          sortType: 'date',
          formatter: (row) => {
            if (!row.date) return '-'
            return this.$date.formatDateTimeWithTimezone(row.date)
          }
        },
        {
          label: this.$t(
            'pages.reports.statistics.discounts.transaction_number'
          ),
          field: 'transaction_number',
          fallback: '-',
          minWidth: 150,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.staff_name'),
          field: 'staff_name',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 150
        },
        {
          label: this.$t('pages.reports.statistics.discounts.product_number'),
          field: 'product_number',
          truncate: true,
          fallback: '-',
          minWidth: 150,
          align: 'right'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.product_name'),
          field: 'product_name',
          truncate: true,
          fallback: '-',
          minWidth: 150,
          align: 'right'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.variant'),
          field: 'variant',
          truncate: true,
          fallback: '-',
          minWidth: 120,
          align: 'right'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.price'),
          field: 'price',
          align: 'right',
          minWidth: 120,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.price)) {
              return this.$formatCurrency(
                row.price,
                row.currency || this.defaultCurrency
              )
            }
            return '-'
          },
          sortType: 'currency'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.quantity'),
          field: 'quantity',
          type: 'number',
          minWidth: 140,
          truncate: true,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t(
            'pages.reports.statistics.discounts.discount_type.label'
          ),
          field: 'discount_type',
          fallback: '-',
          truncate: true,
          minWidth: 140,
          align: 'right',
          formatter: (row) => this.discountTypes[row.discount_type] || '-'
        },
        {
          label: this.$t(
            'pages.reports.statistics.discounts.discount_group.label'
          ),
          field: 'discount_group',
          fallback: '-',
          truncate: true,
          minWidth: 120,
          align: 'right',
          formatter: (row) => this.discountGroups[row.discount_group] || '-'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.discount_name'),
          field: 'discount_name',
          truncate: true,
          align: 'right',
          fallback: '-',
          minWidth: 180
        },
        {
          label: this.$t('pages.reports.statistics.discounts.discount_rate'),
          field: 'discount_rate',
          fallback: '-',
          truncate: true,
          minWidth: 120,
          align: 'right',
          sortType: 'number'
        },
        {
          label: this.$t('pages.reports.statistics.discounts.discount_amount'),
          field: 'discount_amount',
          align: 'right',
          minWidth: 140,
          truncate: true,
          formatter: (row) => {
            if (Number.isFinite(row.discount_amount)) {
              return this.$formatCurrency(
                row.discount_amount,
                row.currency || this.defaultCurrency
              )
            }
            return '-'
          },
          sortType: 'currency'
        }
      ],
      buttons: [
        {
          type: 'custom_export',
          scopes: ['reports_statistics:discounts:export'],
          label: this.$t('common.interactions.buttons.export'),
          clickHandler: () => {
            this.handleExport()
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      branchNumber: 'Config/getCurrentBranchNumber',
      locale: 'Config/getLocale',
      defaultDateSelected: 'Config/getDefaultDateSelected',
      currentLocation: 'Config/getCurrentLocation',
      defaultCurrency: 'Config/getCurrentDefaultCurrency'
    }),
    filtersList() {
      return [
        {
          name: 'discount',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.discounts.discount_name'),
          resource: 'discounts',
          overrideInitialFetchHandler: 'get',
          fetchHandler: 'getAll',
          computedFields: ['name'],
          modifyQuery: (q) => ({ q, limit: 50, deleted: false })
        },
        {
          name: 'product',
          type: 'remote-search-select',
          label: this.$t('pages.reports.statistics.discounts.product'),
          resource: 'products',
          computedFields: ['custom_id', 'name'],
          modifyQuery: (q) => ({
            q,
            limit: 50,
            deleted: false,
            original_product: true
          })
        },
        {
          name: 'discount_rate',
          type: 'input',
          label: this.$t('pages.reports.statistics.discounts.discount_rate')
        },
        {
          name: 'branch_group',
          type: 'remote-search-select',
          doInitialFetch: true,
          label: this.$t('pages.reports.statistics.all.branch_group'),
          resource: 'branchGroups',
          filterable: true,
          optionsValue: 'id',
          disabled: !!this.currentLocation,
          computeName: this.$formatBranch,
          modifyQuery: (q) => ({
            q,
            deleted: false
          })
        },
        {
          name: 'date',
          prop: ['start', 'end'],
          type: 'daterange',
          dateTimeMode: true,
          label: this.$t('pages.transactions.all.filters.date.label'),
          closable: false,
          autoClose: false,
          formatValue: (value) => formatDateRange(value, getDateTimeFormat()),
          default: getRangeFor[this.defaultDateSelected]?.(),
          modifyFilter: (filterObject) => ({
            start: filterObject.start,
            end: filterObject.end
          })
        }
      ]
    },
    parsedQuery() {
      const parsedQuery = (qs.parse(this.$route.query) || {}).filter

      return parsedQuery || {}
    },
    parsedDate() {
      return this.parsedQuery.date || {}
    },
    date() {
      return {
        start: new Date(this.parsedDate.start),
        end: new Date(this.parsedDate.end),
        showDateText: true
      }
    },
    resourceQuery() {
      return {
        legacy: this.isLegacy,
        branch: this.branchNumber || undefined,
        end: this.parsedDate.end || undefined,
        start: this.parsedDate.start || undefined,
        product: this.parsedQuery.product || undefined,
        branch_group: this.parsedQuery.branch_group || undefined,
        discount: this.parsedQuery.discount || undefined,
        discount_rate: this.parsedQuery.discount_rate || undefined
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    discountGroups() {
      return {
        cart: this.$t(
          'pages.reports.statistics.discounts.discount_group.type.cart'
        ),
        customer: this.$t(
          'pages.reports.statistics.discounts.discount_group.type.customer'
        ),
        item: this.$t(
          'pages.reports.statistics.discounts.discount_group.type.item'
        ),
        voucher: this.$t(
          'pages.reports.statistics.discounts.discount_group.type.voucher'
        )
      }
    },
    discountTypes() {
      return {
        percentage: this.$t(
          'pages.reports.statistics.discounts.discount_type.type.percentage'
        ),
        value: this.$t(
          'pages.reports.statistics.discounts.discount_type.type.value'
        )
      }
    }
  },
  watch: {
    'parsedQuery.product': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchProduct(newValue)
        }
      }
    },
    'parsedQuery.discount': {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.fetchDiscount(newValue)
        }
      }
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    customResource() {
      const self = this
      return {
        getAll(params) {
          return pRetry(
            () =>
              new Promise((resolve, reject) => {
                th.analytics({ timeout: 90000 })
                  .getDiscountsReports(self.resourceQuery)
                  .then(({ data }) => {
                    const list =
                      data &&
                      data.find(
                        (d) => safeGet(d, 'metric.job') === 'reports_discounts'
                      )
                    if (list && list.values) {
                      resolve({
                        data: list.values
                      })
                    }

                    resolve({
                      data: []
                    })
                  })
                  .catch((error) => reject(error))
              }),
            {
              retries: 5
            }
          )
        }
      }
    },
    handleLoadingError() {},
    async handleExport() {
      this.$ampli.eventWithBaseProps('statisticsExportButtonClick')
      const query = {
        ...this.resourceQuery,
        format: 'csv'
      }

      try {
        const { data } = await th
          .analytics({ timeout: 90000 })
          .getDiscountsReports(query)

        const exportId = data?.[0]?.correlationId
        if (!exportId) {
          throw new Error(`Response data or correlation ID is missing`)
        }

        useExportsStore().setNewExport({
          exportId,
          payload: {
            originKey: 'pages.reports.statistics.discounts.discounts',
            date: new Date(),
            action: {
              entity: 'analyticsHandlersV1',
              path: 'analytics.reports.AnalyticsReportsDiscounts',
              handler: 'getAll',
              query
            }
          }
        })
      } catch (err) {
        this.$logException(err, {
          message: this.$t('notifications.exports.error.text', {
            entity: this.$t('pages.reports.statistics.discounts.reports.title')
          })
        })
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.table.refresh()
      })
    },
    async fetchProduct(product) {
      try {
        const { data } = await th.products().get(product)
        this.product = data
      } catch (error) {
        this.$logException(error, {
          trackError: false
        })
      }
    },
    async fetchDiscount(discount) {
      try {
        const { data } = await th.discounts().get(discount)
        this.discount = data
      } catch (error) {
        this.$logException(error, {
          trackError: false
        })
      }
    }
  }
}
</script>
